import React from "react"
import ninjaEyes from "../../assets/images/ninja-eyes.png"
import { NextButton } from "../Icons";

export function ComingSoon(props) {
    
    return(
        <div className="base-layout">
            <div className="title">
                decision.ninja
            </div>            
            <img alt="Ninja Eyes" style= {{width: '50vh', paddingTop: '2vh'}} src={ninjaEyes} />
            <div className="large-text">
                personal<br/>decision making<br/>powered by <br/>radical <br/>self-honesty
            </div>
            <div className="large-text">
                coming soon
            </div>
            <NextButton
                root = {true}
                target = "/access-alpha"
                text = "more info"/>
            <NextButton
                root = {true}
                target = "/how-it-works"
                text = "how it works"/>            
        </div>
    )
}