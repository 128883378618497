import React from "react"
import './App.css'
import { RouteSelector } from '../../components/RouteSelector'
import { COMING_SOON } from '../../components/Constants'
import { ComingSoon } from '../../components/pre-alpha/ComingSoon'

const App = () => {

    if (COMING_SOON) {
        return (
            <ComingSoon/>
        )
    }


    return (
        <RouteSelector/>
    )
}
export default App